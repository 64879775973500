<template>
    <div>
<search></search>

        <div class="pb-5">
            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col-12 text-center pb-4">
                        <h2>{{ $t('message.mp_title1') }}</h2>
                        <p class="subtitle">
{{ $t('message.sub_title1') }}
</p>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row">
                        <div class="col-12 col-md-4 pb-4" v-for="item in json.packs">
                            <Card :list="item" :lang="lang"></Card>
                        </div>
                    </div>
                </div>

                <div class="d-bock d-md-none">
                    <carousel></carousel>
                </div>
            </div>
        </div>

        <div class="mp-dark-bg">
            <div class="container">
                <WhyZone></WhyZone>
            </div>
        </div>


        <div class="mp-location-bg pt-5 pb-5">
            <Location></Location>
        </div>
    </div>
</template>

<script>

    import json           from '../../website-data.json';
    import Card           from '../components/Card';
    import CustomCarousel from '../components/Carousel';
    import Location       from '../components/Location';
    import Search         from '../components/Search';
    import WhyZone        from '../components/WhyZone';

    export default {
        name       : 'MainPage',
        components : {
            'carousel' : CustomCarousel,
            Card,
            WhyZone,
            Location,
            'search'   : Search
        },
        data       : function () {
            return {
                json : json,
                lang : this.$route.params.lang
            };
        },
        methods    : {}
    };
</script>
